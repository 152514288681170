<template>
  <div class="whiteBg">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="table_list" style="margin-top: 16px;">
      <el-table
        lazy row-key="sgid"
        :data="superList"
        :load="loadLevel"
        :key="symbolKey"
        :tree-props="{ hasChildren: 'hasChildren' }"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="编号"
          type="index">
          <template slot-scope="scope">
            <span>{{scope.row.index}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="分组名称"
          prop="sgname">
        </el-table-column>
        <el-table-column
          label="展示"
          prop="sgisok">
          <template slot-scope="scope">
            <el-switch
              v-if="scope.row.sgisok === true"
              :value="scope.row.sgisok"
              active-color="#13ce66"
              inactive-color="#A5B7CC"
              @change="changeSwitch(scope.row)">
            </el-switch>
            <el-switch
              v-if="scope.row.sgisok === false"
              :value="scope.row.sgisok"
              active-color="#13ce66"
              inactive-color="#A5B7CC"
              @change="changeSwitch(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="排序">
          <template slot-scope="scope">
            <el-button @click="sortHand(scope.row, 1)" type="primary" size="mini" plain
                 style="display: block">
              上移<i class="el-icon-caret-top el-icon--right"></i>
            </el-button>
            <el-button @click="sortHand(scope.row, 2)" type="primary" size="mini" plain
                 style="display: block;margin-left:0px;margin-top: 5px;">
              下移<i class="el-icon-caret-bottom el-icon--right"></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" size="small" v-if="scope.row.index" @click="addLevel(scope.row)">新建下级</el-button>
              <el-button type="text" size="small" @click="handEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @current-change="pageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>

    <!-- 新增编辑 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="groupForm" :rules="groupRule" ref="groupFrom">
        <el-form-item label="分组名称" prop="sgname">
          <el-input v-model="groupForm.sgname"  placeholder="请输入分组名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  serveGroupAdd,
  serveGroupDel,
  serveGroupEdit,
  serveGroupIsOk,
  serveGroupLevel,
  serveGroupSuper,
  serveGroupOper
} from '@/api/service'

export default {
  name: 'serveGroup',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      superList: [],
      dialogName: '新增',
      fromShow: false,
      groupForm: {
        sgname: '',
        superid: ''
      },
      groupRule: {
        sgname: [
          { required: true, trigger: 'blur', message: '分组名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      symbolKey: '',
      msgList: [] //排序
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = { page_index: this.page, page_size: this.size }
      serveGroupSuper(params).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item, index) => {
              item.index = index + 1
              if (item.isgroup === true) {
                item.hasChildren = true
              } else {
                item.hasChildren = false
              }
            })
            this.superList = res.data.data
          }
          this.symbolKey = Symbol(new Date().toString())
          this.total = res.data.recordcount
        }
      })
    },
    // 加载二级
    loadLevel (tree, treeNode, resolve) {
      serveGroupLevel({ sgid: tree.sgid }).then(res => {
        if (res.status === 1) {
          resolve(res.data.data)
        }
      })
    },
    // 排序移动
    sortHand (row, type) {
      serveGroupOper({ sgid: row.sgid, oper: type }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 点击新增[一级]
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.groupForm = { sgname: '', superid: '' }
    },
    // 点击新增[二级]
    addLevel (row) {
      this.fromShow = true
      this.dialogName = '新增'
      this.groupForm.superid = row.sgid
    },
    FromCancel () {
      this.fromShow = false
      this.$refs.groupFrom.resetFields()
    },
    // 点击编辑
    handEdit (row) {
      this.fromShow = true
      this.dialogName = '编辑'
      this.groupForm = { ...row }
    },
    // 提交[一二级-新增-编辑]
    FromConfirm () {
      this.$refs.groupFrom.validate((valid) => {
        if (!valid) return
        if (this.dialogName === '新增') { // 一二级新增
          const params = {
            sgname: this.groupForm.sgname,
            superid: this.groupForm.superid
          }
          serveGroupAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.dialogName === '编辑') {
          const params = {
            sgname: this.groupForm.sgname,
            sgid: this.groupForm.sgid
          }
          serveGroupEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 展示or不展示
    changeSwitch (row) {
      this.$confirm('确定执行展示操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        serveGroupIsOk({ sgid: row.sgid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 删除
    handDel (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        serveGroupDel({ sgid: row.sgid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
